import React from "react"
import styled from "styled-components"
import Decisions from "./UpdateListing"
import Heading from '../elements/Heading'
import Content from '../Content'
import { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import './update.css'


const Button = styled.button`
  padding: 6px 44px !important;
  background-color: #94040b;
  color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: "Rawline";
  font-size: 20px;
  font-weight: 600;
  border-radius: 50px;
  margin-top: 28px;
`
const Update = () => {

  let [expanded, setExpanded] = useState(false);


  const setExpansionTrue = event => {
    setExpanded(true);
  };
  const setExpansionFalse = event => {
    setExpanded(false);
  };
  const data = useStaticQuery(graphql`
  query {
    allContentfulMarketNewsLegalDecisions(sort: {fields: createdAt,order: DESC}) {
      totalCount
      edges {
        node {
          id
          date
          text {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
    updatesBlock: contentfulPageTextAndHeadings(path: {eq: "/Updates"}) {
      mainHeading1
      buttonText
    }
  }
  `)
  console.log("data count", data.allContentfulMarketNewsLegalDecisions.totalCount)
  return (
    <div className="container w-75">
      <div className="row" >
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5 text-center">
          <div className="w-100 mt-5 mb-5" style={{ textAlign: "-webkit-center" }}>
            <Heading className="headingUpdate"> <Content heading={data.updatesBlock.mainHeading1} /> </Heading>
          </div>
          {

            data.allContentfulMarketNewsLegalDecisions.edges.slice(0, 6).map((edge) => {
              return (
                <Decisions
                  key={edge.node.id}
                  headline={edge.node.date}
                  text={edge.node.text.childContentfulRichText.html}
                />
              )
            })

          }

          <div className="collapse" id="collapseExample">
            {
              data.allContentfulMarketNewsLegalDecisions.edges.slice(6, data.allContentfulMarketNewsLegalDecisions.totalCount).map((edge) => {
                return (
                  <Decisions
                    key={edge.node.id}
                    headline={edge.node.date}
                    text={edge.node.text.childContentfulRichText.html}
                  />
                )
              })
            }
          </div>
          {
            data.allContentfulMarketNewsLegalDecisions.totalCount > 6 ?
              <div>
                {expanded ? (
                  <Button onClick={setExpansionFalse} data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">View Less</Button>

                ) : (
                    <Button onClick={setExpansionTrue} data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">View All</Button>
                  )}
              </div>
              : ""

          }
          {/* <Button data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{data.updatesBlock.buttonText}</Button> */}
        </div>
      </div>
    </div>
  )
}

export default Update
