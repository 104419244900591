import React  from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import UpdatesComp from '../components/Update/Newsupdate'
import { graphql, useStaticQuery } from "gatsby"

const Updates = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulBannerImages(path: {eq: "/Updates"}) {
        image {
          fluid(quality:100) {
            src
          }
        }
        heading
      }
    }
  `)

  const ImgDiv = styled.div`
  background-image: url(${data.contentfulBannerImages.image.fluid.src});
  height: 300px;
  color: #ffffff;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
    return (
      <Layout>
     
            <ImgDiv>
            <h3
              style={{
                fontFamily: "Playfair Display",
                fontSize: 50,
                fontWeight: "bold",
                textAlign: "center",
                margin: "auto",
                paddingTop: 100,
              }}
            >
              {data.contentfulBannerImages.heading}
            </h3>
          </ImgDiv>
          <UpdatesComp/>
      </Layout>
    )
}
export default Updates
