import React from 'react'
import styled from "styled-components"


const Date = styled.div`
color: #94040b;
font-weight: 600;
margin-bottom: 1rem;
width: 400px;
`
const updateListing = ({ headline, text }) => {
  return (
    <div className="text-justify mt-4">
      <p style={{ border: "none", fontFamily: "Rawline", marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: text }} />
      <Date><p>{headline}</p></Date>
    </div>
  )

}
export default updateListing
